$(document).on('click touchstart', '.analytics_event', function ( event )
{
    var $this = $(this);
    var data = $this.data();
    var category = ( data.category !== undefined ) ? data.category : 'Link';
    var action = ( data.action !== undefined ) ? data.action : 'Click';
    var label = ( data.label !== undefined ) ? data.label : ( $this.attr('title') || $this.text() );
    var value = ( data.value !== undefined ) ? data.value : 0;
    //console.log('send_analytics_hit: ' + category + ', ' + action + ', ' + label + ', ' + value);return;

    if ( typeof(value) === 'undefined' )
    {
        value = 0;
    }

    // Google Analytics
    if ( window.ga )
    {
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
        // send, event, [category], [action], [label], [value](number)
        window.ga('send', 'event', category, action, label, value);
    }
    else if ( window._gaq )
    {
        //var _gaq = window._gaq || [];
        window._gaq.push(['_trackEvent', category, action, label, value]);
    }
});


// Google Adwords - Phone Number - Add a span with class number

(function(a,e,c,f,g,b,d){var h={ak:"924203881",cl:"OqhbCJL4nmYQ6fbYuAM"};a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};a[f]||(a[f]=h.ak);b=e.createElement(g);b.async=1;b.src="//www.gstatic.com/wcm/loader.js";d=e.getElementsByTagName(g)[0];d.parentNode.insertBefore(b,d);a._googWcmGet=function(b,d,e){a[c](2,b,h,d,null,new Date,e)}})(window,document,"_googWcmImpl","_googWcmAk","script");

$(document).ready(function(){
    _googWcmGet('number', '216-291-1101');
});