$("#calculator-form").submit(function(e){
    e.preventDefault();
    var length = $("input[name='length']").val();
    var width = $("input[name='width']").val();
    var depth = $("input[name='depth']").val();

    length = length ? length : 1;
    width = width ? width : 1;
    depth = depth ? depth : 1;

    var volume = length * width * depth/324;
    volume = Math.ceil(volume);

    $("#calculator-output").html("<p>You will need:</p>" + volume + " cubic yard(s)").show();
});

$("#calculator-reset").on("click", function (e) {
    e.preventDefault();
    $("#calculator-output").hide();
    $("#calculator-output").html("");
    $("input[name='length']").val('');
    $("input[name='width']").val('');
    $("input[name='depth']").val('');
});
