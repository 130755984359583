$("#rates-list").change(function(e){
    var choice = $(this).find(":selected");
    var name = choice.data("name");
    var price = choice.data("price");

    if(!isNaN(price)){
        $("#rates-output")
            .html("$" + price + ".00").removeClass("disabled");
    }
    else{
        $("#rates-output").html(price).removeClass("disabled");
    }
});
