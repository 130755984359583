$(document.body).click(function (e) {
    $("#nav-toggle").removeClass("expanded");
    $(".navbar").removeClass('expanded');
    $("#nav-overlay.mask").remove();
});
$('#nav-toggle').click(function(event) {
    $(this).toggleClass('expanded');
    $(".navbar").toggleClass('expanded');
    if($("#nav-overlay.mask").length >= 1){
        $("#nav-overlay.mask").remove()
    }
    else{
        $(document.body).prepend("<div class='mask' id='nav-overlay'></div>");
    }
    event.stopPropagation();
});
$(".navbar  ul a.dropdown-toggle").click(function(e){
    e.preventDefault();
    $(this).toggleClass('active');
    $(this).siblings("ul").first().toggleClass('expanded');
});

$('#scroll-top').click(function(){
    $('html, body').animate({
        scrollTop: $('body').offset().top
    }, 500);
    return false;
});

function scroll(){
    // Variables
    var windowHeight = $(window).height();

    //Get the phone element
    var phone = $('.phone-bar');
    var sibling = phone.next();

    //Find it's top
    var stickPoint = phone.position().top;
    var releasePoint = sibling.position().top - phone.height();

    // Store the document scroll function in a variable
    var y = $(document).scrollTop();

    if(phone.hasClass('sticky')){
        if(y < releasePoint){
            phone.removeClass('sticky');
            sibling.css("padding-top", parseInt(sibling.css("padding-top")) - phone.height());
        }
    }
    else{
        if( y > stickPoint) {
            // Add the "sticky" class
            phone.addClass('sticky');
            sibling.css("padding-top", parseInt(sibling.css("padding-top")) + phone.height());
        }
    }

}

//Phone Number Scroll Lock
$(document).ready(function(){

    // Even when the window is resized, run this code.
    $(window).resize(function(){

        document.addEventListener("touchmove", scroll, false);
        document.addEventListener("scroll", scroll, false);

        // Call it on resize.
    }).resize();

}); // jQuery
